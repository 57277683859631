




































import {
  Component, Vue,
} from 'vue-property-decorator';
import EditEmergencyFundSuccessViewModel
  from '@/vue-app/view-models/components/goals-dashboard/edit-plan-goals/edit-emergency-fund-goal-plan/edit-emergency-fund-success-view-model';

@Component({ name: 'EditEmergencyFundSuccess' })
export default class EditEmergencyFundSuccess extends Vue {
  edit_emergency_fund_define_by_view_model = Vue.observable(
    new EditEmergencyFundSuccessViewModel(this),
  );
}
